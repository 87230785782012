import React, { useState } from "react";
import "./People.css"; // Import CSS for styling the page

// Import images
import XinjieChenImage from "../assets/team-autograph/Xinjie-Chen-New-Large.png";
import CharlotteHonImage from "../assets/team-autograph/Charlott-Hon-large.jpg";
import ZhenyiZhouImage from "../assets/team-autograph/Zhenyi-Zhou-medium.jpg";
import MaisieGlofcheskiImage from "../assets/team-autograph/Maisie-Glofcheski-large.jpg";
import YixinWangImage from "../assets/team-autograph/Yixin-Wang-large.jpg"; // Adjust path based on your project

const People = () => {
  // Team members data
  const members = [
    {
      name: "Maisie Glofcheski",
      summary:
        "Maisie specializes in language education and is a doctoral student at CUHK.",
      bio: `I am originally from Ottawa, Canada. I received my Bachelor of Arts (B.A.) in Psychology at Trent University, and my Master of Education (M.Ed.) in Societies, Cultures, and Language at the University of Ottawa, Canada. In 2019, I moved to Asia to start a new journey as a tertiary lecturer at HKUST. Presently, I am a doctoral student at CUHK.`,
      image: MaisieGlofcheskiImage,
    },
    {
      name: "Charlotte Hon",
      summary:
        "Charlotte is a teacher and wellbeing practitioner based in Hong Kong.",
      bio: `I majored in Psychology and Linguistics (M.A. First Class Honours) at The University of Edinburgh, Scotland. In 2022, I embarked on my teaching journey at a local primary school in Hong Kong. I have a growing passion for tennis and yoga. Love a good social!`,
      image: CharlotteHonImage,
    },
    {
      name: "Yixin Wang",
      summary:
        "Yixin focuses on learner motivation and teaching in underdeveloped areas.",
      bio: `I am pursuing a Bachelor of Arts at Tsinghua University, specializing in second language acquisition and foreign literature. My research focuses on the effects of teaching methodologies on learner motivation and engagement. I'm dedicated to teaching in underdeveloped areas and advocating for curriculum innovation.`,
      image: YixinWangImage,
    },
    {
      name: "Zhenyi Zhou",
      summary:
        "Zhenyi's research explores positive psychology and emotional development.",
      bio: `I received my bachelor’s degree of Arts in Education from Beijing Normal University in 2022. I’m currently a master student in the Department of Psychology in CUHK. My research includes positive psychology (e.g., mindfulness-based interventions and resilience), emotional and cognitive development across the life span with quantitative methods and meta-analysis. Outside the lab, I stay active through badminton and unwind with friends over mahjong.`,
      image: ZhenyiZhouImage,
    },
  ];

  const director = [
    {
      name: "Dr. CHEN Xinjie, Nathalie",
      summary:
        "Dr. CHEN is the director of LEaPP Lab, specializing in psychology and education.",
      bio: [
        "Dr. CHEN Xinjie is an Assistant Professor in the Faculty of Education at CUHK. She is a multilingual speaker with teaching experience across countries.",
        "Her research includes educational psychology, second language teaching, and positive psychology. She has numerous publications in top journals.",
        "Currently, Dr. CHEN sits on editorial boards and has served as a guest editor for various journals.",
      ],
      contact: "https://www.fed.cuhk.edu.hk/eps/CHENN.html",
      image: XinjieChenImage,
    },
  ];

  const [expandedMemberIndex, setExpandedMemberIndex] = useState(null);

  const toggleDetails = (index) => {
    setExpandedMemberIndex(expandedMemberIndex === index ? null : index);
  };

  return (
    <div className="people-container">
      <h1 className="people-title">Our Team</h1>

      {/* Director Section */}
      <h2 className="team-section-title">Director</h2>
      <div className="team-grid director-card">
        {director.map((member, index) => (
          <div key={index} className="team-card">
            <div className="team-card-image-container">
              <img
                src={member.image}
                alt={member.name}
                className="team-card-image"
              />
            </div>
            <div className="team-card-body">
              <h3 className="team-card-name">{member.name}</h3>
              <p className="team-card-summary">
                {expandedMemberIndex === index
                  ? member.bio.join(" ")
                  : member.summary}
              </p>
              <span
                className="more-details"
                onClick={() => toggleDetails(index)}
              >
                {expandedMemberIndex === index ? "Show less" : "More details"}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Members Section */}
      <h2 className="team-section-title">Members</h2>
      <div className="team-grid">
        {members.map((member, index) => (
          <div key={index} className="team-card">
            <div className="team-card-image-container">
              <img
                src={member.image}
                alt={member.name}
                className="team-card-image"
              />
            </div>
            <div className="team-card-body">
              <h3 className="team-card-name">{member.name}</h3>
              <p className="team-card-summary">
                {expandedMemberIndex === index + director.length
                  ? member.bio
                  : member.summary}
              </p>
              <span
                className="more-details"
                onClick={() => toggleDetails(index + director.length)}
              >
                {expandedMemberIndex === index + director.length
                  ? "Show less"
                  : "More details"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default People;
